<!--
* 列表条目组件
-->
<template>
    <van-cell :value="amount" :class="{'item-left':true, 'type-r-e':'INCOME'==itemData.typeRE}" @click="itemClick(itemData.id)">
        <template #title>
            <span class="list-item-icon">
                <icon-svg :icon-class="itemData.iconCode" />
            </span>
            <van-tag class="list-item-tag">{{itemData.iconName}}</van-tag>
        </template>
    </van-cell>
    <van-popup v-model:show="show" teleport="#app" position="right" closeable close-icon-position="top-left"
    :style="{ height: '100%',width: '100%' }" @close="billingPopupCloseListen" @open="billingPopupOpenListen">
        <cm-billing @billClose="itemPopupClose" :id="ItemId" :bartitle="bartitle" :option="billShowState"/>
    </van-popup>
</template>

<script>
import { ref,reactive } from 'vue';
import { toCurrency } from "@/utils/currencyUtil";
export default {
    components: {

    },
    props:{
        itemData:{
            name:{
                type:String
            },
            icon:{
                type:String
            },
            amount:{
                type:Number
            },
            id:{
                type:Number
            },
            typeRE:{
                type:String
            }
        }
    },
    emits: ["itemBillingClose"],
    setup(props, context) {
        const show = ref(false);
        const bartitle = ref('编辑');
        const itemPopupClose=()=>{
            show.value=false;
            if(!show.value){
                context.emit('itemBillingClose');
            }
        }
        const ItemId = ref('');
        const itemClick=(id)=>{
            show.value=true;
            ItemId.value=id;
            function pushHistory() {
                const state = { title: 'itemBilling', url: '#' };
                window.history.pushState(state, state.title, state.url);
            }
            pushHistory();
        }
        window.addEventListener('popstate', function () {
            if (show.value) {
                show.value = false;
            }
        });
        const amount = ref(toCurrency(props.itemData.amount));
        const billShowState = reactive({showState:false});
        const billingPopupOpenListen=()=>{
            billShowState.showState = true;
        }

        //添加页面关闭事件
        const billingPopupCloseListen = () => {
            billShowState.showState = false;
        }
        return {
            show,
            bartitle,
            ItemId,
            itemClick,
            itemPopupClose,
            amount,
            billShowState,
            billingPopupCloseListen,
            billingPopupOpenListen
        }
    }
}
</script>

<style scoped>
.item-left {
    text-align: left;
}

.type-r-e /deep/ .van-cell__value {
    color:green;
}

.list-item-icon {
    margin-right: 0.2rem;
}
.list-item-tag{
    background: white!important;
    color: rgb(97, 97, 97)!important;
}

/deep/ .van-cell__value {
    color:red;
}
</style>

