<template>
    <van-grid :column-num="2">
        <van-grid-item icon="photo-o" text="文字"><span>应收</span><span>{{ collectAmount }}</span></van-grid-item>
        <van-grid-item icon="photo-o" text="文字"><span>应付</span><span>{{ payAmount }}</span></van-grid-item>
    </van-grid>
</template>

<script>
import { ref, watch } from 'vue';
import { toCurrency } from "@/utils/currencyUtil";
export default {
    props: {
        option: {
            totalCollectAmount: {
                type: Number,
                default: 0
            },
            totalPayAmount: {
                type: Number,
                default: 0
            }
        }
    },
    setup(props) {
        const collectAmount = ref(0);
        const payAmount = ref(0);
        watch(props.option, () => {
            collectAmount.value = toCurrency(props.option.totalCollectAmount);
            payAmount.value = toCurrency(props.option.totalPayAmount);
        }, {
            deep: true
        });
        return {
            collectAmount,
            payAmount

        }
    }
}
</script>

<style scoped>
.van-hairline--top::after {
    border-top-width: 0rem;
}

/deep/ .van-grid-item__content {
    font-size: 0.4rem;
    background-color: #27c181;
    color: white;
}

/deep/ .van-grid-item__content::after {
    z-index: 1;
    border-width: 0px var(--van-border-width-base) 0px;
    top: 12%;
    height: 1.5rem;
    border-block-color: white;
}
</style>