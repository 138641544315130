<template>
      <van-grid :column-num="3">
        <van-grid-item>
          <span>{{MonthlyCumulative.expenses.title}}</span>
          <span>{{MonthlyCumulative.expenses.amount}}</span>
        </van-grid-item>
        <van-grid-item @click="showMtmc">
          <span>收支差额</span>
          <span>{{MonthlyCumulative.diffamount}}</span>
        </van-grid-item>
        <van-grid-item>
          <span>{{MonthlyCumulative.income.title}}</span>
          <span>{{MonthlyCumulative.income.amount}}</span>
        </van-grid-item>       
      </van-grid>
    <van-popup v-model:show="mtmcShow" teleport="#app" position="right" closeable close-icon-position="top-left"
        :style="{ width:'100%',height: '100%' }">
       <mtmc/>
    </van-popup>
</template>

<script>
import request from "../utils/request";
import mtmc from '@/components/MonthToMonthComparison';
import { toCurrency } from "@/utils/currencyUtil";
import { ref } from 'vue';
export default {
    components:{
        mtmc
    },
    props:{
        reqDate:{
          type:Number,
          default:new Date().getTime()
        }
    },
    setup(props){
        const option = props;

        const getMonthlyCumulative = () => {
          let now = new Date();
          let curmenth = now.getMonth() + 1;
          const mc = {
              expenses: {
              title: curmenth + '月支出',
              amount: 0
              },
              income: {
              title: curmenth + '月收入',
              amount: 0
              },
              diffamount: 0
          };
          return mc;
        }
        const MonthlyCumulative = ref(getMonthlyCumulative());
        const loadMonthlyCumulativeData = async () => {
          request({
              method: 'post',
              url: "/api/pb/detail/mc",
              data:{'date':option.reqDate}
          }).then(function (response) {
              //console.log('response');
              //console.log(response);
              //console.log(response.data);
              MonthlyCumulative.value.expenses.amount = toCurrency(response.data.expensesAmount);
              MonthlyCumulative.value.income.amount = toCurrency(response.data.incomeAmount);
              MonthlyCumulative.value.diffamount = toCurrency((response.data.incomeAmount - response.data.expensesAmount).toFixed(2));
          }).catch(function (error) {
              console.log(error);
          });
        };
        loadMonthlyCumulativeData();
        const refresh=()=>{
          loadMonthlyCumulativeData();
        };
        const mtmcShow = ref(false);
        const showMtmc=()=>{
          mtmcShow.value = true;
            function pushHistory() {
                const state = { title: 'billing', url: '#' };
                window.history.pushState(state, state.title, state.url);
            }
            pushHistory();
        }
        window.addEventListener('popstate', function () {
            if (mtmcShow.value) {
                mtmcShow.value = false;
            }
        });
        
         return {
            MonthlyCumulative,
            refresh,
            mtmcShow,
            showMtmc
        };
    }
}
</script>

<style scoped>
.van-hairline--top::after {
    border-top-width: 0rem;
}
/deep/ .van-grid-item__content {
  font-size: 0.4rem;
  background-color: #ec4141;
  color : white;
}
/deep/ .van-grid-item__content::after {
    z-index: 1;
    border-width: 0px var(--van-border-width-base) 0px;
    top: 12%;
    height: 1.5rem;
    border-block-color: white;
}
</style>