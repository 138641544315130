
<template>
  <div style="height:20%">
    <div class="user" @click="userInfo">
        <van-icon name="user-o" size="26" color="#FFFF" @click="userInfo"/>
    </div>
    <van-nav-bar :title="pbName" :class="navBarClass" @click="switchBook" />
    <van-grid :column-num="2" v-show="switchBookShow">
      <van-grid-item icon="balance-pay" text="日常生活" @click="everyDayLife" />
      <van-grid-item icon="debit-pay" text="有借有还" @click="borrowRepay" />
    </van-grid>
    <div v-show="everyDayLifeShow">
      <monthly-cumulative ref="monthlyCumulativeRef" />
    </div>
    <div v-show="borrowRepayShow">
      <cumulativeBR  :option="cumulativeBROption"/>
    </div>     
  </div>
  <div style="height:80%">
    <van-config-provider :theme-vars="themeVars" v-show="everyDayLifeShow">
      <van-pull-refresh v-model="loading" @refresh="onRefresh" head-height="80" pull-distance="80">
        <div class="compContainer">
          <!--收支流水-->
          <RevenueExpenditure ref="RevenueExpenditureRef" />
          <!--支出分类-->
          <ExpenditureClassification ref="ExpenditureClassificationRef" />
          <!--消费趋势-->
          <ConsumerTrends ref="ConsumerTrendsRef" />
        </div>
      </van-pull-refresh>
    </van-config-provider>
    <div v-show="borrowRepayShow"> 
      <ListBR @getData="getBorrowData"/>
    </div>
  </div>
  <van-tabbar route :placeholder="true">
    <van-tabbar-item replace to="/" icon="balance-pay">钱花哪了</van-tabbar-item>
    <van-tabbar-item replace to="/tools/market" icon="shopping-cart-o">超市助手</van-tabbar-item>
    <van-tabbar-item @click="FutureCli" icon="balance-o">理财</van-tabbar-item>
    <van-tabbar-item @click="FutureCli" icon="user-o">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { reactive, ref } from 'vue';
import { Toast } from 'vant';
import RevenueExpenditure from '@/components/RevenueExpenditure';
import ExpenditureClassification from '@/components/ExpenditureClassification';
import ConsumerTrends from "@/components/ConsumerTrends";
import cumulativeBR from "@/components/borrowRepay/cumulativeBR";
import ListBR from "@/components/borrowRepay/ListBR";
//import request from "../utils/request";
export default {
  name: "HomePage",
  components: {
    RevenueExpenditure,
    ExpenditureClassification,
    ConsumerTrends,
    cumulativeBR,
    ListBR
  },
  setup() {
    const themeVars = {
      '--van-cell-background-color': 'red'
    };

    const FutureCli=()=>{
      Toast('模块还在建设中...');
    }

    const pbName = ref('日常生活');
    const switchBookShow = ref(false);
    const switchBook = () => {
      switchBookShow.value = !switchBookShow.value;
    }

    const everyDayLifeShow = ref(true);
    const borrowRepayShow = ref(false);
    const navBarClass=ref('page-nav-bar');
    //日常生活
    const everyDayLife = () => {
      pbName.value = "日常生活";
      everyDayLifeShow.value = true;
      borrowRepayShow.value = false;
      switchBookShow.value = false;
      navBarClass.value='page-nav-bar';
    }
    //有借有还
    const borrowRepay = () => {
      pbName.value = "有借有还";
      everyDayLifeShow.value = false;
      borrowRepayShow.value = true;
      switchBookShow.value = false;
      navBarClass.value='page-nav-bar-green';
    }
    const cumulativeBROption = reactive({
      totalCollectAmount:0,
      totalPayAmount:0
    });
    const getBorrowData=(data)=>{
      cumulativeBROption.totalCollectAmount = data.totalCollectAmount;
      cumulativeBROption.totalPayAmount = data.totalPayAmount;
    }

    const monthlyCumulativeRef = ref();
    const RevenueExpenditureRef = ref();
    const ExpenditureClassificationRef = ref();
    const ConsumerTrendsRef = ref();

    //下拉刷新
    const loading = ref(false);
    const onRefresh = () => {
      RevenueExpenditureRef.value.refresh();
      monthlyCumulativeRef.value.refresh();
      ExpenditureClassificationRef.value.refresh();
      ConsumerTrendsRef.value.refresh();
      setTimeout(() => {
        Toast('刷新成功');
        loading.value = false;
        console.log('下拉刷新成功');
      }, 1000);
    }

    const listItemClick = () => {
      console.log('listItemClick');
    }

    const userInfo = ()=>{
      console.log('userInfo');
    }
    return {
      FutureCli,
      themeVars,
      pbName,
      switchBookShow,
      navBarClass,
      everyDayLife,
      borrowRepay,
      everyDayLifeShow,
      borrowRepayShow,
      getBorrowData,
      cumulativeBROption,
      switchBook,
      loading,
      onRefresh,
      listItemClick,
      monthlyCumulativeRef,
      RevenueExpenditureRef,
      ExpenditureClassificationRef,
      ConsumerTrendsRef,
      userInfo
    };
  },
};
</script>

<style scoped>
/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0rem;
}

.compContainer {
  height: 16rem;
  overflow: scroll;
}

.title-left {
  text-align: left;
}

.list-item-icon {
  margin-right: 0.2rem;
}
.user {
  float: left;
  width: 1rem;
  height: 1rem;
  z-index: 2;
  position: fixed;
  left: 0.3rem;
  top: 0.1rem;
}
</style>