import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { NavBar,Tabbar, TabbarItem,Col,Form, Field, Row, Cell, CellGroup,Button,
    Icon,Toast,ConfigProvider,List,Calendar,DatetimePicker,Switch,Popup,Collapse,
    CollapseItem,Grid, GridItem,PullRefresh,Tag ,DropdownMenu, DropdownItem,Tab, 
    Tabs,Swipe, SwipeItem,Popover,Divider,Dialog,Picker   } from 'vant';
import 'vant/lib/index.css';
import './styles/index.css';
import 'amfe-flexible';
import request from "./utils/request";
import IconSvg from '@/components/icon-component';
import CreateBilling from '@/components/CreateBilling';
import MonthlyCumulative from '@/components/MonthlyCumulative';
import ListItem from '@/components/ListItem';
import ECharts from '@/components/ECharts';


const app = createApp(App);

app.component('icon-svg', IconSvg);
app.component('cm-billing', CreateBilling);
app.component('monthly-cumulative', MonthlyCumulative);
app.component('list-item', ListItem);
app.component('ECharts', ECharts);
app.use(NavBar);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Form);
app.use(Field);
app.use(Col);
app.use(Row);
app.use(Cell);
app.use(CellGroup);
app.use(Button);
app.use(Icon);
app.use(Toast);
app.use(ConfigProvider);
app.use(List);
app.use(Calendar);
app.use(DatetimePicker);
app.use(Switch);
app.use(Popup );
app.use(Collapse);
app.use(CollapseItem);
app.use(Grid);
app.use(GridItem);
app.use(PullRefresh);
app.use(Tag);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Tab);
app.use(Tabs);
app.use(Swipe);
app.use(SwipeItem);
app.use(Popover);
app.use(Divider);
app.use(Dialog);
app.use(Picker);
app.use(router).mount('#app')
app.config.globalProperties.$http=request;
