<template>
  <van-nav-bar :title="bartitle">
    <template #right>
      <van-icon name="delete-o" size="18" v-show="showRemove" @click="removeItem" />
    </template>
  </van-nav-bar>
  <div class="billing-container">
    <van-form @submit="billSave">

      <van-cell-group inset>
        <van-field name="switch" :label="RESwitch.labelVal">
          <template #input>
            <van-switch v-model="RESwitch.checked" size="20" @change="RESwitch.RETypeChange" class="income-expend" />
          </template>
        </van-field>
      </van-cell-group>

      <!-- 可以使用 CellGroup 作为容器 -->
      <van-cell-group inset>
        <van-field ref="billingAmountRef" :label="typeChoice.title" maxlength="9" placeholder="¥0.00"
          :class="RESwitch.billingAmountClass" input-align="right" type="number" :rules="billForm.amountValue"
          :autofocus="true" v-model="amountValue" />
      </van-cell-group>

      <div class="billing-type-panel">
        <van-swipe>
          <van-swipe-item v-for="(subIconItem, idx) in icons" :key="idx">
            <van-grid>
              <van-grid-item v-for="(item, index) in subIconItem" :key="index" @click="choiceType(idx, index)"
                icon="photo-o" text="文字">
                <icon-svg :icon-class="item.icon" />{{ item.name }}
              </van-grid-item>
            </van-grid>
          </van-swipe-item>
        </van-swipe>
      </div>
      <van-cell-group inset>
        <van-field v-model="date" is-link readonly name="calendar" label="日历" placeholder="点击选择日期"
          @click="show = true" />
        <van-calendar v-model:show="show" :show-confirm="false" @confirm="onConfirm" :min-date="minDate"
          :max-date="maxDate" :default-date="defaultDate" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="currentDate" is-link readonly name="datetimePicker" label="时间选择" placeholder="点击选择时间"
          @click="showPicker = true" />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-datetime-picker type="time" @confirm="onConfirmTime" @cancel="showPicker = false" />
        </van-popup>
      </van-cell-group>
      <van-cell-group inset>
        <van-field label="备注" placeholder="备注" input-align="left" v-model="desc" />
      </van-cell-group>

      <van-button type="success" size="large" native-type="submit">保存</van-button>
    </van-form>
  </div>
</template>

<script>
import iconsAll from "@/assets/data/BillTypeIcon";
import { reactive, ref, nextTick, onMounted, watch } from 'vue';
import request from "../utils/request";
import { Toast } from 'vant';

export default {
  name: 'createBill',
  emits: ['billClose'],
  props: {
    bartitle: {
      type: String,
      default: '记一笔'
    },
    id: {
      type: Number
    },
    option: {
      showState: {
        type: Boolean,
        default: false
      }
    }
  },
  setup(props, context) {
    const option = props;

    const icons = ref();
    //消费类型-title和index
    const typeChoice = reactive({
      title: '餐饮买菜',
    });
    let currIconIndex = [0, 0];
    //记账类型：支持/收入
    const RESwitch = ref({
      checked: false,
      labelVal: '支出',
      labelCode: 'EXPEND',
      billingAmountClass: 'billing-amount-expend',
      RETypeChange: (value) => {
        //console.log(value);
        if (value) {
          RESwitch.value.labelVal = '收入';
          RESwitch.value.labelCode = 'INCOME';
          RESwitch.value.billingAmountClass = 'billing-amount-income';
          typeChoice.title = '工资';
        } else {
          RESwitch.value.labelVal = '支出';
          RESwitch.value.labelCode = 'EXPEND';
          RESwitch.value.billingAmountClass = 'billing-amount-expend';
          typeChoice.title = '餐饮买菜';
        }
        RESwitch.value.checked = value;
        currIconIndex = [0, 0];
        icons.value = RESwitch.value.screeningIcons();
      },
      screeningIcons: () => {
        let screeningRS = new Array();
        let screeningRSSsub = new Array();
        let siconsCounter = 1;
        iconsAll.forEach((i) => {
          if (i.type === RESwitch.value.labelCode) {
            screeningRSSsub.push(i);
            if (0 == siconsCounter % 12) {
              screeningRS.push(screeningRSSsub);
              siconsCounter = 0;
              screeningRSSsub = new Array();
            }
            siconsCounter++;
          }
        });
        if (screeningRSSsub.length > 0) {
          screeningRS.push(screeningRSSsub);
        }
        return screeningRS;
      }
    });
    //消费金额
    const amountValue = ref('');
    const billingAmountRef = ref();
    const billAmountFocus = () => {
      billingAmountRef.value.focus();
    }
    onMounted(() => {
      nextTick(() => {
        billAmountFocus();
      });
    });
    watch(props.option, () => {
      console.log(props.option.showState);
      if (props.option.showState) {
        nextTick(() => {
          billAmountFocus();
        });
      }
    });

    //消费类型
    icons.value = RESwitch.value.screeningIcons();
    const choiceType = (idx, i) => {
      currIconIndex[0] = idx;
      currIconIndex[1] = i;
      typeChoice.title = icons.value[idx][i].name;
    }
    //日历初始化
    const getDate = () => {
      let date = new Date();
      return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
    }
    const date = ref(getDate());
    const show = ref(false);

    const formatDate = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const onConfirm = (value) => {
      show.value = false;
      date.value = formatDate(value);
    };
    //时间选择
    const getTime = () => {
      let date = new Date();
      return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
    }
    const currentDate = ref(getTime());
    const showPicker = ref(false);
    const onConfirmTime = (value) => {
      currentDate.value = value;
      showPicker.value = false;
    };

    //备注
    const desc = ref('');

    //校验
    const billForm = {
      amountValue: [{ required: true, message: "请输入金额" }, { pattern: /^\d+(\.\d{1,2})?$/, message: "金额格式错误" },],
    };
    //编辑初始化
    const modifyLoad = async () => {
      console.log(option.id);
      const toast1 = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      request({
        method: 'post',
        url: "/api/pb/detail/billing",
        data: { "id": option.id }
      }).then(function (response) {
        //console.log('response');
        //console.log(response);
        //console.log(response.data);
        //重新赋值
        RESwitch.value.RETypeChange(response.data.reType);
        amountValue.value = response.data.amount;

        icons.value.forEach((subItem, idx) => {
          subItem.forEach((item, index) => {
            if (item.icon === response.data.iconCode) {
              currIconIndex[0] = idx;
              currIconIndex[1] = index;
              typeChoice.title = icons.value[idx][index].name;
              return;
            }
          });
        });
        let resdate = new Date(response.data.time);
        date.value = resdate.getFullYear() + '-' + (resdate.getMonth() + 1).toString().padStart(2, '0') + '-' + resdate.getDate().toString().padStart(2, '0');
        currentDate.value = resdate.getHours().toString().padStart(2, '0') + ':' + resdate.getMinutes().toString().padStart(2, '0');
        desc.value = response.data.desc;
        toast1.clear();
      }).catch(function (error) {
        console.log('error');
        console.log(error);
      });
    }
    const showRemove = ref(false);
    if (null != option.id && option.id > 0) {
      modifyLoad();
      showRemove.value = true;
    }
    //重新初始化值
    const restDefault = () => {
      RESwitch.value.RETypeChange(false);
      amountValue.value = 0;
      let resdate = new Date();
      date.value = resdate.getFullYear() + '-' + (resdate.getMonth() + 1).toString().padStart(2, '0') + '-' + resdate.getDate().toString().padStart(2, '0');
      currentDate.value = resdate.getHours().toString().padStart(2, '0') + ':' + resdate.getMinutes().toString().padStart(2, '0');
      desc.value = "";
    }
    //删除
    const removeItem = async () => {
      const toast1 = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      request({
        method: 'post',
        url: "/api/pb/detail/remove",
        data: { "id": option.id }
      }).then(function (response) {
        //console.log('response');
        //console.log(response);
        //console.log(response.data);
        toast1.clear();
        if ('success' === response.data.code) {
          const toast2 = Toast.success('删除成功');
          restDefault();
          toast2.clear();
          context.emit('billClose', false);
        } else {
          const toast2 = Toast.fail('保存失败');
          toast2.clear();
        }
      }).catch(function (error) {
        console.log('error');
        console.log(error);
      });
    }

    //提交
    const billSave = async () => {
      const toast1 = Toast.loading({
        message: '保存中...',
        forbidClick: true,
      });
      let billing = {
        id: option.id,
        billDate: date.value + ' ' + currentDate.value + ':00',
        reType: RESwitch.value.labelCode,
        billType: icons.value[currIconIndex[0]][currIconIndex[1]].code,
        amount: amountValue.value,
        description: desc.value
      };
      request({
        method: 'post',
        url: "/api/pb/detail/save",
        data: billing
      }).then(function (response) {
        //console.log('response');
        //console.log(response);
        //console.log(response.data);
        toast1.clear();
        if ('success' === response.data.code) {
          const toast2 = Toast.success('保存成功');
          restDefault();
          toast2.clear();
          context.emit('billClose', false);
        } else {
          const toast2 = Toast.fail('保存失败');
          toast2.clear();
        }

      }).catch(function (error) {
        console.log('error');
        console.log(error);
      });
    }
    return {
      RESwitch,
      icons,
      amountValue,
      billingAmountRef,
      date,
      show,
      minDate: new Date(2022, 4, 8),
      maxDate: new Date(),
      defaultDate: new Date(),
      onConfirm,
      currentDate,
      onConfirmTime,
      showPicker,
      typeChoice,
      choiceType,
      desc,
      billForm,
      billSave,
      showRemove,
      removeItem,
    };
  },

}
</script>

<style scoped>
.billing-container {
  height: 18rem;
  overflow: scroll;
}

.income-expend {
  margin-left: 4.6rem;
  background: red;
}

.van-switch--on {
  background: green !important;
}

.billing-amount-income {
  /* font-size: 1rem; */
}

/deep/.billing-amount-income .van-field__control--right {
  font-size: 0.6rem !important;
  color: green;
}

/deep/.billing-amount-income .van-field__control--right::-webkit-input-placeholder {
  color: green;
}

.billing-amount-expend {
  /* font-size: 1rem; */
}

/deep/.billing-amount-expend .van-field__control--right {
  font-size: 0.6rem !important;
  color: red;
}

/deep/.billing-amount-expend .van-field__control--right::-webkit-input-placeholder {
  color: red;
}

.billing-type-panel {
  font-size: 0.4rem;
  color: grey;
}

.svg-icon {
  width: 1.5em !important;
  height: 1.5em !important;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>