<!--
消费趋势|日消费曲线
-->
<template>
    <van-cell-group>
        <van-cell title="消费趋势 | 日消费曲线" title-class="title-left" is-link />
    </van-cell-group>
    <ECharts id="incDailyChart" cheight="150px" :option="option" />
</template>

<script>
import { reactive } from 'vue';
import request from "../utils/request";
export default {
    setup() {
        const option = reactive({
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                left: '12%',
                right: '10%',
                bottom: '55',
                top: '9'
            },
            xAxis: {
                type: 'category',
                data: []
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: [],
                    type: 'line',
                    smooth: true,
                    color: 'red'
                }
            ]
        });
        let thisMonth = new Date();
        let monthDay = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), 0).getDate();
        for (let i = 1; i <= monthDay; i++) {
            option.xAxis.data.push(i);
        }
        const getMonthDaily=async ()=>{
            request({
                method: 'post',
                url: "/api/pb/detail/monthDaily"
            }).then(function (response) {
                option.series[0].data = response.data;
            }).catch(function (error) {
                console.log('error');
                console.log(error);
            });
        }
        getMonthDaily();

        const refresh=()=>{
           getMonthDaily();
        };
        return {
            option,
            refresh
        }
    }
}
</script>

<style scoped>
/deep/ .van-cell__title {
    color: grey;
}
</style>