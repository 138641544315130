<!--
* 收支流水
-->
<template>
    <van-cell-group>
        <van-cell title="收支流水" title-class="title-left" is-link @click="showAllList" />
        <div v-for="(it, index) in recentConsumptionData" :key="index">
            <van-cell :title="it.date" title-class="title-left" :value="it.sum" class="summary" />
            <list-item :itemData="detail" v-for="(detail) in it.items"
                :key="detail.id + detail.typeRE + detail.iconCode + detail.amount"
                @itemBillingClose="itemBillingClose" />
        </div>
        <van-cell>
            <van-button icon="plus" type="primary" round block color="#ec4141" size="mini" @click="billingClick" />
        </van-cell>
    </van-cell-group>
    <van-popup v-model:show="show" teleport="#app" position="right" closeable close-icon-position="top-left"
        :style="{ height: '100%', width: '100%' }">
        <reList></reList>
    </van-popup>
    <van-popup v-model:show="billingShow" teleport="#app" position="bottom" closeable close-icon-position="top-left"
        @close="billingPopupCloseListen" @open="billingPopupOpenListen" :style="{ height: '100%' }">
        <cm-billing @billClose="billingPopupClose" :option="billShowState"/>
    </van-popup>
</template>

<script>
import reList from '@/components/RevenueExpenditureList';
import request from "../utils/request";
import { reactive, ref } from 'vue';

export default {
    components: {
        reList
    },
    setup() {
        const recentConsumptionData = ref([{
            date: '',
            sum: '',
            items: null,

        }, {
            date: '',
            sum: '',
            items: null,

        }]);

        const initRecentConsumptionData = (data) => {
            data.forEach((element, index) => {
                let date = new Date(element.date);
                let today = new Date();
                var yestday = new Date(today.getTime() - 24 * 3600 * 1000);
                if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
                    recentConsumptionData.value[index].date = '今天';
                } else if (yestday.getFullYear() === date.getFullYear() && yestday.getMonth() === date.getMonth() && yestday.getDate() === date.getDate()) {
                    recentConsumptionData.value[index].date = '昨天';
                } else {
                    recentConsumptionData.value[index].date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                }

                let sumText = '';
                if (0 != element.iamountTotal) {
                    sumText = '收入 ' + element.iamountTotal + ' ';
                }
                if (0 != element.eamountTotal) {
                    sumText += '支出 ' + element.eamountTotal;
                }
                recentConsumptionData.value[index].sum = sumText;
                recentConsumptionData.value[index].items = element.items;
            });
            console.log(recentConsumptionData.value);
        }
        const loadDate = async () => {
            request({
                method: 'post',
                url: "/api/pb/detail/recentConsumption"
            }).then(function (response) {
                initRecentConsumptionData(response.data);
            }).catch(function (error) {
                console.log('error');
                console.log(error);
            });
        }
        loadDate();
        function pushHistory(titleName) {
            const state = { title: titleName, url: '#' };
            window.history.pushState(state, state.title, state.url);
        }
        const show = ref(false);
        const showAllList = () => {
            //console.log('showAllbtn click');
            show.value = true;
            pushHistory('reList');
        }
        const refresh = () => {
            loadDate();
        };

        //添加页面是否显示
        const billingShow = ref(false);
        //添加按钮
        const billingClick = () => {
            billingShow.value = true;
            pushHistory('billing');
        }
        window.addEventListener('popstate', function (event) {
            if (billingShow.value) {
                billingShow.value = false;
            }
            if (show.value && 'reList'!=event.state.title) {
                show.value = false;
            }
        });
        const billShowState = reactive({showState:false});
        const billingPopupOpenListen=()=>{
            billShowState.showState = true;
        }

        //添加页面关闭事件
        const billingPopupCloseListen = () => {
            console.log('监听到添加窗口关闭了');
            billShowState.showState = false;
            //刷新当前组件的数据
            loadDate();
        }
        //添加窗口关闭方法
        const billingPopupClose = () => {
            billingShow.value = false;
        }
        //修改或删除关闭刷新数据
        const itemBillingClose = () => {
            console.log('itemBillingClose');
            loadDate();
        }
        return {
            recentConsumptionData,
            showAllList,
            show,
            refresh,
            billingClick,
            billingShow,
            billShowState,
            billingPopupOpenListen,
            billingPopupCloseListen,
            billingPopupClose,
            itemBillingClose
        }
    }
}
</script>

<style scoped>
.summary {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    background-color: #f7f8fa !important;
    font-size: 0.2rem !important;
}

/deep/ .van-cell__title {
    color: grey;
}
</style>