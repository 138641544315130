import axios from 'axios';
import storage from './storage';
import router from '../router/index';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
const request = axios.create({
    
    baseURL: " /",
    //headers: { 'content-type': 'application/json;charset=UTF-8' },
    proxy: {
        host: '127.0.0.1',
        port: 8081,
    }

    //请求拦截器
    //响应拦截器
});
request.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// 添加请求拦截器
request.interceptors.request.use(
  (config)=> {
      //do......
      // 在发送请求之前进行操作
      //console.log('在发送请求之前进行操作');
      if(!('/api/login' === config.url)){
        //console.log('当前不是登陆请求需要验证token');
        //console.log(storage.tokenKey);
        var token  = storage.getItem(storage.tokenKey);
        if(null==token  || undefined == token || ''==token){
            //console.log('token 不存在');
            router.push('/login');
        }else{
          //console.log(token);
          config.headers.common['token'] =token;
        }
      }
      return config;
  },
  function (error) {
      //do......
      // 对请求错误进行操作
      console.log('对请求错误进行操作');
      return Promise.reject(error);
  }
);
//响应拦截器
request.interceptors.response.use(
  (res)=> {
    return res;
  },(error)=>{
    if(error.response.status===401){
        router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default request;