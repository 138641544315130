<!--
* 支出分类
-->
<template>
    <van-cell-group>
        <van-cell title="支出分类" title-class="title-left" is-link />
        <van-cell>
            <ECharts id="expClassification" cheight="160px" :option="option" />
        </van-cell>
    </van-cell-group>
</template>

<script>

import { reactive } from 'vue';
import request from "../utils/request";
import { toCurrency } from "@/utils/currencyUtil";
export default {

    setup() {

        const option = reactive({
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                y: 'cenotr',
                top: '5%',
                //left: 'center',
                data: ['A']
            },
            series: [
                {
                    type: 'pie',
                    radius: ['60%', '80%'],
                    center: ["30%", "50%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center',
                        emphasis: {
                            show: true
                        }
                    },

                    labelLine: {
                        show: false
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    data: []
                }
            ]
        }
        );
        const getECData = async () => {
            request({
                method: 'post',
                url: "/api/pb/detail/expenditureClassification"
            }).then(function (response) {
               
                let ecNameArray = [];
                let seriesDataArray=[];
                let totalAmount = 0 ;
                response.data.forEach(element => {
                    totalAmount+=element.value;
                });
                response.data.forEach(element => {
                    let name = null;
                    if(element.value===0){
                        name = element.name+' '+element.value;
                    }else{
                        name = element.name+' '+toCurrency(element.value)+' ('+((element.value/totalAmount)*100).toFixed(2)+'%)';
                    }
                    
                    seriesDataArray.push({'name':name,'value':element.value});
                    ecNameArray.push(name);
                });
                //注意legend.data与series.data的name值要保持一致否则不会显示
                option.legend.data=ecNameArray;
                option.series[0].data = seriesDataArray;
                //console.log('option.legend.data');
                //console.log(option.legend.data);
            }).catch(function (error) {
                console.log('error');
                console.log(error);
            });
        };
        getECData();
        const refresh=()=>{
           getECData();
        };
        return {
            option,
            refresh
        }
    }
}
</script>

<style scoped>
/deep/ .van-cell__title {
    color: grey;
}
</style>