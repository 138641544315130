<template>
    <div>
        <van-row justify="center" align="center">
            <van-col span="24" @click="showDatePicker = true" class="center">
                <van-dropdown-menu>
                    <van-dropdown-item :title="analysisDate"></van-dropdown-item>
                </van-dropdown-menu>
            </van-col>
            <van-switch v-model="yearChecked" size="18" class="y-m-switch-btn" @change="yearMonthWitchChange"/>
        </van-row>
        <van-popup v-model:show="showDatePicker" teleport="#app" position="right"
            :style="{ height: '50%', width: '100%' }">
            <van-datetime-picker v-model="currentDate" :type="yearMonthType.type" :title="yearMonthType.title" :min-date="minDate"
                :max-date="maxDate" :columns-order="['month', 'year']" :formatter="formatter" @change="currentDateChange" @confirm="confirmDate" @cancel="cancelChoose" />
        </van-popup>
    </div>
    <ECharts id="analysisHistogram" cheight="2rem" :option="analysisHistogramOption" /> 
    <van-cell title="支出分类" title-class="title-centor" />
    <ECharts id="analysisExpendType" cheight="13rem" :option="expendOption" />
    <van-cell title="收入分类" title-class="title-left" />
    <ECharts id="analysisIncomeType" cheight="13rem" :option="incomeOption" />
</template>

<script>
import { ref, reactive } from 'vue';
import request from "../utils/request";
import { toCurrency } from "@/utils/currencyUtil";
export default {
    setup() {
        let dateNow = new Date();
        const analysisDate = ref(dateNow.getFullYear()+"");
        const yearChecked = ref(true);
        const yearMonthType=ref({type:'year-month',title:'选择年月'});
        const currentDate = ref(dateNow);
        const showDatePicker = ref(false);

        const confirmDate = () => {
            if (yearChecked.value) {
                analysisDate.value = currentDate.value.getFullYear()+"";
            }else{
                analysisDate.value = currentDate.value.getFullYear() + '年' + (currentDate.value.getMonth() + 1).toString().padStart(2, '0') + '月';
            }
            showDatePicker.value = false;
        }
        const cancelChoose = () => {
            showDatePicker.value = false;
        }

        const formatter = (type, val) => {
            if (type === 'year') {
                return `${val}年`;
            }
            if (type === 'month') {
                return `${val}月`;
            }
            return val;
        };

        //柱状图
        const analysisHistogramOption = reactive(
            {

                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    top: '3%',
                    left: '3%',
                    right: '16%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: ['结余', '支出', '收入']
                },
                series: [
                    {
                        //name: '2011',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'right'
                        },
                        data: [
                            {
                                value: 0,
                                itemStyle: {
                                    color: 'blue'
                                }
                            },
                            {
                                value: 0,
                                itemStyle: {
                                    color: 'red'
                                }
                            },
                            {
                                value: 0,
                                itemStyle: {
                                    color: 'green'
                                }
                            }
                        ]
                    }
                ]
            }
        );

        //支出分类
        const expendOption = reactive({
             tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                y: 'cenotr',
                top: '5%',
                //left: 'center',
                data: ['A']
            },
            series: [
                {
                    type: 'pie',
                    radius: ['30%', '40%'],
                    center: ["25%", "45%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center',
                        emphasis: {
                            show: true
                        }
                    },

                    labelLine: {
                        show: false
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    data: []
                }
            ]
        }
        );
        //收入分类
        const incomeOption = reactive({
             tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                y: 'cenotr',
                top: '5%',
                //left: 'center',
                data: ['A']
            },
            series: [
                {
                    type: 'pie',
                    radius: ['30%', '40%'],
                    center: ["25%", "45%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center',
                        emphasis: {
                            show: true
                        }
                    },

                    labelLine: {
                        show: false
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    data: []
                }
            ]
        }
        );
        const getAnalysisData = async () => {
            let dataParam = '';
            if(yearChecked.value){
                dataParam = currentDate.value.getFullYear();
            }else{
                dataParam = currentDate.value.getFullYear()+'-'+(currentDate.value.getMonth()+1).toString().padStart(2, '0');
            }
            request({
                method: 'post',
                url: "/api/pb/detail/analysis",
                data:{'date':dataParam}
            }).then(function (response) {

                let histogram = response.data.histogram;
                analysisHistogramOption.series[0].data[0].value=histogram[0];
                analysisHistogramOption.series[0].data[1].value=histogram[1];
                analysisHistogramOption.series[0].data[2].value=histogram[2];
               
                let expendPieChart = response.data.expendPieChart;
                let incomePieChart = response.data.incomePieChart;
                function pieDataChange(cdata){
                    let ecNameArray = [];
                    let seriesDataArray=[];
                    let totalAmount = 0 ;
                    cdata.forEach(element => {
                        totalAmount+=element.value;
                    });
                    cdata.forEach(element => {
                        let name = element.name+' '+toCurrency(element.value)+' ('+((element.value/totalAmount)*100).toFixed(2)+'%)';
                        seriesDataArray.push({'name':name,'value':element.value});
                        ecNameArray.push(name);
                    });  
                    return {
                        'ecNameArray':ecNameArray,
                        'seriesDataArray':seriesDataArray
                    } 
                }

                let expendPieData = pieDataChange(expendPieChart);
                //注意legend.data与series.data的name值要保持一致否则不会显示
                expendOption.legend.data=expendPieData.ecNameArray;
                expendOption.series[0].data = expendPieData.seriesDataArray;

                let incomePieData = pieDataChange(incomePieChart);
                incomeOption.legend.data=incomePieData.ecNameArray;
                incomeOption.series[0].data = incomePieData.seriesDataArray;
            }).catch(function (error) {
                console.log('error');
                console.log(error);
            });
        };
        getAnalysisData();
        const yearMonthWitchChange=()=>{
            if(yearChecked.value){
                analysisDate.value= dateNow.getFullYear()+"";
            }else{
                analysisDate.value = dateNow.getFullYear() + '年' + (dateNow.getMonth() + 1).toString().padStart(2, '0') + '月';
            }
            currentDate.value=dateNow;
            //刷新数据
            getAnalysisData();
        }
        const currentDateChange=()=>{
            getAnalysisData();
        }

        return {
            analysisDate,
            yearChecked,
            yearMonthType,
            yearMonthWitchChange,
            showDatePicker,
            minDate: new Date(2018, 0, 1),
            maxDate: new Date(),
            formatter,
            currentDate,
            confirmDate,
            cancelChoose,
            analysisHistogramOption,
            expendOption,
            incomeOption,
            currentDateChange
        }
    }
}
</script>

<style scoped>
.year-month {
    font-size: 0.5rem;
}
.year-month::after {
    position: absolute;
    top: 26%;
    right: 4.99rem;
    margin-top: -0.13333rem;
    border: 0.08rem solid;
    border-color: transparent transparent var(--van-gray-4) var(--van-gray-4);
    transform: rotate(-45deg);
    opacity: .8;
    content: '';
}
.y-m-switch-btn {
    float: right;
    position: relative;
    top: -0.9rem;
    right: -4rem;
}
.center{
  text-align: center;
}
</style>