import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MaiCai from '../views/tools/MaiCai.vue'
//import MarketHelp from '../views/tools/MarketHelp.vue'
import MarketHelp2 from '../views/tools/MarketHelp2.vue'
//创建路由规则
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tools/market',
    name: 'MarketHelp2',
    component: MarketHelp2
  },
  {
    path: '/tools/maicai',
    name: 'MaiCai',
    component: MaiCai
  }

]
//创建路有实例
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
