<template>
  <van-nav-bar title="超市助手" left-text="返回" left-arrow  @click-left="onClickLeft"/>
  <van-row justify="end">
    <van-col span="3">
      <van-button type="primary" color="green" size="mini">未购</van-button>
    </van-col>
    <van-col span="3">
      <van-button type="primary" color="grey" size="mini">已购</van-button>
    </van-col>
  </van-row>

  <div style="height: 14rem;overflow: scroll;" v-show="!shoppingShow">

    <van-divider>辅料-其他</van-divider>
    <van-grid :column-num="6" square>
      <van-grid-item v-for="item in fuliao" :key="item.code" @click="Fchange(item.code)">
        <van-button type="primary" :color="item.state" size="small">{{item.name}}</van-button>
      </van-grid-item>
      <van-grid-item>
        <van-popover v-model:show="showPopover" :actions="actions" @select="onSelect">
          <template #reference>
            <van-button icon="plus" type="primary" color="green" size="small" />
          </template>
        </van-popover>
      </van-grid-item>
    </van-grid>
    <van-popup v-model:show="show" position="bottom">
      <van-cell-group inset>
        <van-field v-model="FName" ref="FnameRef" autofocus size="large" required label="辅料名称" placeholder="请输入辅料名称" />
      </van-cell-group>
      <van-button type="success" round block @click="addFuliao">确认</van-button>
    </van-popup>
    <!--菜品-->
    <van-divider>菜品-主料</van-divider>
    <van-row v-for="cp in CAIPIN" :key="cp.name">
      <van-col span="6" align="center">
        <van-tag plain type="primary" size="large" closeable @close="Cremove(cp.name)">{{cp.name}}</van-tag>
      </van-col>
      <van-col span="18">
        <van-grid :column-num="4" square>
          <van-grid-item v-for="zitem in cp.zhuliao" :key="zitem.code" @click="Cchange(cp.name,zitem.code)">
            <van-button type="primary" :color="zitem.state" size="small">{{zitem.name}}</van-button>
          </van-grid-item>
          <van-grid-item>
            <van-button icon="plus" type="primary" color="green" size="small" @click="addZhuLiao(cp.name)" />
          </van-grid-item>
        </van-grid>
      </van-col>
    </van-row>

    <van-popup v-model:show="Cshow" position="bottom">
      <van-cell-group inset>
        <van-field v-model="CName" ref="CNameRef" autofocus size="large" required label="主料名称" placeholder="请输入主料名称" />
        <van-field v-model="CtypeResult" is-link readonly name="picker" label="主料分类" placeholder="点击选择分类"
          @click="CTypeshowPicker = true" />
        <van-popup v-model:show="CTypeshowPicker" position="bottom">
          <van-picker :columns="CTypecolumns" @confirm="CTypeonConfirm" @cancel="CTypeshowPicker = false" />
        </van-popup>
      </van-cell-group>
      <van-button type="success" round block @click="addZhuLiaoConform">确认</van-button>
    </van-popup>
    <!--添加菜品-->
    <van-row>
      <van-col span="24" align="center">
        <van-button round icon="plus" type="primary" color="green" size="small" @click="CAdd" />
      </van-col>
    </van-row>

    <van-popup v-model:show="Caipinshow" position="bottom">
      <van-cell-group inset>
        <van-field v-model="CaipinName" ref="CaipinNameRef" autofocus size="large" required label="菜品名称"
          placeholder="请输入菜品名称" />
        <van-field v-model="CaipinZLName" size="large" required label="主料名称" placeholder="请输入主料名称" />
        <van-field v-model="CaipinZLResult" is-link readonly name="picker" label="主料分类" placeholder="点击选择分类"
          @click="CaipinZLshowPicker = true" />
        <van-popup v-model:show="CaipinZLshowPicker" position="bottom">
          <van-picker :columns="CaipinZLcolumns" @confirm="CaipinZLonConfirm" @cancel="CaipinZLshowPicker = false" />
        </van-popup>
      </van-cell-group>
      <van-button type="success" round block @click="addCAIPINConform">确认</van-button>
    </van-popup>
  </div>

  <div style="height: 14rem;overflow: scroll;" v-show="shoppingShow">
    <van-row v-for="it in shoppingDate" :key="it.type+it.detail.length">
      <van-col span="4" align="center">
        <van-tag plain type="primary" size="large">{{it.type}}</van-tag>
      </van-col>
      <van-col span="20">
        <van-grid :column-num="4" square>
          <van-grid-item v-for="item in it.detail" :key="item.code" @click="Cchange(item.cname,item.code)">
            <van-button type="primary" :color="item.state" size="small">{{item.name}}</van-button>
          </van-grid-item>
        </van-grid>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="4" align="center">
        <van-tag plain type="primary" size="large">辅料</van-tag>
      </van-col>
      <van-col span="20">
        <van-grid :column-num="6" square>
          <van-grid-item v-for="item in fuliao" :key="item.code" @click="Fchange(item.code)">
            <van-button type="primary" :color="item.state" size="small">{{item.name}}</van-button>
          </van-grid-item>
        </van-grid>
      </van-col>
    </van-row>
  </div>

  <van-row>&nbsp;</van-row>
  <!--结束购物-->
  <van-row gutter="20" align="center" justify="center">
    <van-col span="10">
      <van-button type="danger" round block @click="EndShopping">结束购物</van-button>
    </van-col>
    <van-col span="10">
      <van-button type="success" round block @click="GoShopping" v-show="!shoppingShow">去购物</van-button>
      <van-button type="success" round block @click="GoCPView" v-show="shoppingShow">添加菜品</van-button>
    </van-col>

  </van-row>
</template>

<script>
import { nextTick, ref, watch } from 'vue';
import { Dialog } from 'vant';
import router from '../../router/index';
export default {
  setup() {
    const onClickLeft =()=>{
      router.push('/');
    }
    const FU_LIAO = 'FU_LIAO';
    const changeStorage = (key, obj) => {
      window.localStorage.setItem(key, JSON.stringify(obj));
    }
    const getItem = (key) => {
      return window.localStorage.getItem(key);
    }

    const state = ['green', 'grey'];
    const fuliao = ref([
      /*
      {
      name: '葱',
      code: 'cong',
      state: state[0]
      }
      */
    ]);
    let FULIAOVAL = getItem(FU_LIAO);
    if (null != FULIAOVAL) {
      fuliao.value = JSON.parse(FULIAOVAL);
    }

    const Fchange = (code) => {

      fuliao.value.forEach(element => {
        if (element.code === code) {
          if (state[0] === element.state) {
            Dialog.confirm({
              title: '已购买？',
              //message:'',
            })
              .then(() => {
                element.state = state[1];
                changeStorage(FU_LIAO, fuliao.value);
              })
              .catch(() => {
                // on cancel
              });
          }
          if (state[1] === element.state) {
            Dialog.confirm({
              title: '确认删除？',
            })
              .then(() => {
                fuliao.value.pop(element);
                changeStorage(FU_LIAO, fuliao.value);
              })
              .catch(() => {
                // on cancel
              });
          }
        }
      });

    }

    const showPopover = ref(false);
    const actions = [
      { text: '葱' },
      { text: '姜' },
      { text: '蒜' },
      { text: '自定义' },
    ];

    const show = ref(false);

    const onSelect = (action) => {
      if (actions[0].text === action.text) {
        fuliao.value.push({ name: '葱', code: 'cong', state: state[0] });
        changeStorage(FU_LIAO, fuliao.value);
      }
      if (actions[1].text === action.text) {
        fuliao.value.push({ name: '姜', code: 'jiang', state: state[0] });
        changeStorage(FU_LIAO, fuliao.value);
      }
      if (actions[2].text === action.text) {
        fuliao.value.push({ name: '蒜', code: 'suan', state: state[0] });
        changeStorage(FU_LIAO, fuliao.value);
      }
      if (actions[3].text === action.text) {
        show.value = true;
      }
    };
    const FnameRef = ref();
    watch(show, (newVal) => {
      nextTick(() => {
        newVal && FnameRef.value.focus();
      });
    });
    const FName = ref('');
    const addFuliao = () => {
      let fuliao1 = { name: '葱', code: 'cong', state: state[0] };
      fuliao1.name = FName.value;
      fuliao1.code = '' + fuliao.value.length + 1;
      fuliao.value.push(fuliao1);
      show.value = false;
      FName.value = '';
      changeStorage(FU_LIAO, fuliao.value);
    }

    //菜品
    const CAIPIN = ref([
      /*
      {
        name: '红烧肉',
        zhuliao: [
          {
            name: '猪肉',
            type:'',
            code: 'cong',
            state: state[0]
          }
        ]
      }
      */
    ]);
    const CAIPINKEY = 'CAI_PIN';
    let CAIPINVAL = getItem(CAIPINKEY);
    if (null != CAIPINVAL) {
      CAIPIN.value = JSON.parse(CAIPINVAL);
    }
    //添加pop
    const Cshow = ref(false);
    //主料名称
    const CName = ref('');
    const Caipinshow = ref(false);
    const CNameRef = ref();
    watch(Cshow, (newVal) => {
      nextTick(() => {
        newVal && CNameRef.value.focus();
      });
    });

    //主料类型
    const typeColumns = ['蔬菜', '水果', '肉类', '家禽', '水产','豆制品', '水果','熟食','酒水','干货', '零食','速食','粮油', '日用品','彩妆', '烘焙', '其他'];
    const CtypeResult = ref('其他');
    const CTypeshowPicker = ref(false);
    const CTypecolumns = typeColumns;
    const CTypeonConfirm = (value) => {
      CtypeResult.value = value;
      CTypeshowPicker.value = false;
    };

    //添加菜品
    const CAdd = () => {
      Caipinshow.value = true;
    }
    const CaipinName = ref('');
    const CaipinZLName = ref('');
    const CaipinNameRef = ref();
    watch(Caipinshow, (newVal) => {
      nextTick(() => {
        newVal && CaipinNameRef.value.focus();
      });
    });

    const CaipinZLResult = ref('其他');
    const CaipinZLshowPicker = ref(false);
    const CaipinZLcolumns = typeColumns;
    const CaipinZLonConfirm = (value) => {
      CaipinZLResult.value = value;
      CaipinZLshowPicker.value = false;
    };

    const addCAIPINConform = () => {
      CAIPIN.value.push({
        name: CaipinName.value,
        zhuliao: [
          {
            name: CaipinZLName.value,
            code: CaipinZLName.value + '1',
            type: CaipinZLResult.value,
            state: state[0]
          }
        ]
      });
      CaipinName.value = '';
      CaipinZLName.value = '';
      Caipinshow.value = false;
      changeStorage(CAIPINKEY, CAIPIN.value);
    }
    //添加菜品主料
    let currModifyCaiPin = '';
    const addZhuLiao = (name) => {
      Cshow.value = true;
      currModifyCaiPin = name;
    }
    const addZhuLiaoConform = () => {
      CAIPIN.value.forEach((element) => {
        if (element.name === currModifyCaiPin) {
          element.zhuliao.push({ name: CName.value, type: CtypeResult.value, code: CName.value + (element.zhuliao.length + 1), state: state[0] });
          changeStorage(CAIPINKEY, CAIPIN.value);
        }
      });
      CName.value = '';
      Cshow.value = false;
    }
    //修改主料状态
    const Cchange = (name, code) => {
      CAIPIN.value.forEach((element) => {
        if (element.name === name) {
          element.zhuliao.forEach((el) => {
            if (el.code === code) {
              if (state[0] === el.state) {
                Dialog.confirm({
                  title: '已购买？',
                  //message:'',
                })
                  .then(() => {
                    el.state = state[1];
                    changeStorage(CAIPINKEY, CAIPIN.value);
                  })
                  .catch(() => {
                    // on cancel
                  });
              }
              if (state[1] === el.state) {
                Dialog.confirm({
                  title: '确认删除？',
                })
                  .then(() => {
                    element.zhuliao.pop(element);
                    changeStorage(CAIPINKEY, CAIPIN.value);
                  })
                  .catch(() => {
                    // on cancel
                  });
              }
            }
          });
        }
      });
    }
    //移除菜品
    const Cremove = (name) => {
      CAIPIN.value.forEach((element) => {
        if (name === element.name) {
          Dialog.confirm({
            title: '不做这道菜？',
          })
            .then(() => {
              CAIPIN.value.pop(element);
              changeStorage(CAIPINKEY, CAIPIN.value);
            })
            .catch(() => {
              // on cancel
            });
        }
      });
    }
    const shoppingDate = ref([]);
    const shoppingShow = ref(false);
    const shoppingShowKey = 'SHOPPING-VIEW';

    const changeViewData = () => {
      shoppingDate.value = [];
      const map = new Map();
      CAIPIN.value.forEach((el) => {
        el.zhuliao.forEach((zel) => {
          let typeArray = map.get(zel.type);
          if (undefined === typeArray) {
            typeArray = [];

          }
          zel.cname = el.name;
          typeArray.push(zel);
          map.set(zel.type, typeArray);
        });
      });

      map.forEach((value, key) => {
        shoppingDate.value.push({ type: key, detail: value });
      });
    }
    let shoppingShowVal = getItem(shoppingShowKey);
    if (null != shoppingShowVal) {
      if ('false' === shoppingShowVal) {
        shoppingShow.value = false;
      } else if ('true' === shoppingShowVal) {
        shoppingShow.value = true;
      }
      changeViewData();
    }
    watch(shoppingShow, (newVal) => {
      nextTick(() => {
        window.localStorage.setItem(shoppingShowKey, newVal);
      });
    });

    const GoShopping = () => {
      changeViewData();
      shoppingShow.value = true;
    }
    watch(CAIPIN.value, (newVal) => {
      nextTick(() => {
        newVal && changeViewData();
      });
    });

    const GoCPView = () => {
      shoppingShow.value = false;
    }

    const EndShopping = () => {
      Dialog.confirm({
        title: '都买好了？',
        message: '清空全部记录？',
      })
        .then(() => {
          fuliao.value = [];
          CAIPIN.value = [];
          window.localStorage.removeItem(FU_LIAO);
          window.localStorage.removeItem(CAIPINKEY);
          shoppingShow.value = false;
          router.push('/');
        })
        .catch(() => {
          // on cancel
        });
    }
    return {
      onClickLeft,
      fuliao,
      actions,
      onSelect,
      showPopover,
      show,
      FnameRef,
      FName,
      addFuliao,
      Fchange,
      EndShopping,
      //菜品
      CAIPIN,
      Cremove,
      addZhuLiao,
      Cchange,
      CAdd,
      Cshow,
      CName,
      CNameRef,
      CtypeResult,
      CTypeshowPicker,
      CTypecolumns,
      CTypeonConfirm,
      addZhuLiaoConform,
      Caipinshow,
      addCAIPINConform,
      CaipinName,
      CaipinZLName,
      CaipinNameRef,
      CaipinZLResult,
      CaipinZLshowPicker,
      CaipinZLcolumns,
      CaipinZLonConfirm,
      GoShopping,
      shoppingDate,
      shoppingShow,
      GoCPView

    }
  }

}
</script>

<style scoped>

</style>