<template>
    <div :id="id" :style="{width:cwidth,height:cheight}"></div>
</template>

<script>
import { defineComponent,onMounted,watch } from "vue";
import * as echarts from 'echarts';
export default defineComponent ({
    props: {    //父组件需要传递的参数：id，width，height，option
        id: {
            type: String,
            default: "mcm"
        },
        cwidth: {
            type: String,
            default: "100%"
        },
        cheight: {
            type: String, 
            default: "300px"
        },
        option: {
            type: Object,
            //Object类型的prop值一定要用函数return出来，不然会报错。原理和data是一样的，      
            //使用闭包保证一个vue实例拥有自己的一份props      
            default: null
                
        }
    },
    setup(props){
        
        let charts=null;
        let initEcharts=()=>{
            if(!charts){
                charts = echarts.init(document.getElementById(props.id));
            }else{
                return;
            }
            if(!props.option){
                return;
            }
            charts.setOption(props.option);
            window.addEventListener("resize", echarts.resize);
        }
        onMounted(() => {
            initEcharts();
        });
        watch(props.option,()=> {
            if(charts){
                charts.setOption(props.option);
            }
            
        },{
            deep:true
        });
        return {

        }
    }
});
</script>

<style>
</style>