const iconsAll = [
    {
      "code": "FOOD_SHOPPING",
      "icon": "canyin",
      "name": "餐饮买菜",
      "type": "EXPEND"
    },
    {
      "code": "SNACKS_DRINKS",
      "icon": "a-50-62_fuzhi-13",
      "name": "零食饮料",
      "type": "EXPEND"
    },
    {
      "code": "TRANSPORTATION",
      "icon": "jiaotongfei",
      "name": "交通",
      "type": "EXPEND"
    },
    {
      "code": "LEISURE_ENTERTAINMENT",
      "icon": "xiuxianyule",
      "name": "休闲娱乐",
      "type": "EXPEND"
    },
    {
      "code": "HOUSING",
      "icon": "housing",
      "name": "住房",
      "type": "EXPEND"
    },
    {
      "code": "LEND",
      "icon": "jiechu",
      "name": "借出",
      "type": "EXPEND"
    },
    {
      "code": "OTHER_EXPENSES",
      "icon": "qitazhichu",
      "name": "其他支出",
      "type": "EXPEND"
    },
    {
      "code": "MEDICAL",
      "icon": "yiliao",
      "name": "医疗",
      "type": "EXPEND"
    },
    {
      "code": "LEARNING",
      "icon": "xuexi",
      "name": "学习",
      "type": "EXPEND"
    },
    {
      "code": "HOME",
      "icon": "jiaju",
      "name": "家居",
      "type": "EXPEND"
    },
    {
      "code": "SKIN_CARE_MAKEUP",
      "icon": "hufupin",
      "name": "护肤彩妆",
      "type": "EXPEND"
    },
    {
      "code": "DIGITAL",
      "icon": "shuma",
      "name": "数码",
      "type": "EXPEND"
    },
    {
      "code": "DAILY_NECESSITIES",
      "icon": "riyongpin",
      "name": "日用品",
      "type": "EXPEND"
    },
    {
      "code": "TOBACCO_ALCOHOL",
      "icon": "yanjiu",
      "name": "烟酒",
      "type": "EXPEND"
    },
    {
      "code": "SOCIAL_CONTACT",
      "icon": "shejiao",
      "name": "社交",
      "type": "EXPEND"
    },
    {
      "code": "TEA",
      "icon": "cha",
      "name": "茶",
      "type": "EXPEND"
    },
    {
      "code": "YING",
      "icon": "gouwudai",
      "name": "莹",
      "type": "EXPEND"
    },
    {
      "code": "CLOTHES_SHOES_HATS",
      "icon": "yifu",
      "name": "衣服鞋帽",
      "type": "EXPEND"
    },
    {
      "code": "SOFTWARE",
      "icon": "ruanjian-",
      "name": "软件",
      "type": "EXPEND"
    },
    {
      "code": "COMMUNICATION_NETWORK",
      "icon": "tongxun",
      "name": "通讯网费",
      "type": "EXPEND"
    },
    {
      "code": "SALARY",
      "icon": "wodegongzi",
      "name": "工资",
      "type": "INCOME"
    },
    {
      "code": "INVESTMENT_INCOME",
      "icon": "touzi",
      "name": "投资收入",
      "type": "INCOME"
    },
    {
      "code": "COLLECT_MONEY",
      "icon": "shoukuan1",
      "name": "收款",
      "type": "INCOME"
    },
    {
      "code": "RED_ENVELOPE",
      "icon": "hongbao",
      "name": "红包",
      "type": "INCOME"
    },
    {
      "code": "WELFARE",
      "icon": "fuli",
      "name": "福利",
      "type": "INCOME"
    },
    {
      "code": "MEAL_MONEY",
      "icon": "shoukuan",
      "name": "饭钱",
      "type": "INCOME"
    },
    {
      "code": "OTHER_INCOME",
      "icon": "qitashouru",
      "name": "其他收入",
      "type": "INCOME"
    }
  ];

  export default iconsAll;