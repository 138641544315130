<template>
    <van-pull-refresh v-model="refreshing" offset="0" @refresh="onRefresh">
        <div class="br-panel">
            <van-list v-model:loading="loading" :finished="finished" finished-text="" @load="onLoad">

                <div class="borrow-repay-item" v-for="(item, index) in borrowData.summary" :key="index">
                    <van-row class="row-date">
                        <van-col span="24"><span class="ml5">
                                <van-icon name="weapp-nav" />
                            </span><span class="ml5">{{item.date}}</span></van-col>
                    </van-row>
                    <van-row class="row-desc">
                        <van-col span="4">
                            <icon-svg icon-class="jiechu" />
                        </van-col>
                        <van-col span="8">
                            <div><span>借给</span><span>{{item.borrower}}</span></div>
                            <div><span>已收款:</span><span>{{item.collectedAmount}}</span></div>
                            <div><span>待收款:</span><span>{{item.receivablesAmount}}</span></div>
                        </van-col>
                        <van-col span="6">
                            <van-button v-show="item.settle" class="finish-btn" type="success" size="large" round>已结清</van-button>
                        </van-col>
                        <van-col span="6">
                            <div :class="{'unsettle':!item.settle}">¥{{item.receivablesAmount}}</div>
                            <div><span>利息收入:</span><span>0</span></div>
                            <div><span>&nbsp;</span></div>
                        </van-col>
                    </van-row>
                </div>
            </van-list>
        </div>
    </van-pull-refresh>
    <van-row span="24" justify="center" class="add-btn-par">
        <van-button class="add-btn" icon="plus" size="large" type="success" @click="addBorrow">新建款项</van-button>
    </van-row>
</template>

<script>
import { Toast } from 'vant';
import { ref } from 'vue';
import request from "@/utils/request";
import { toCurrency } from "@/utils/currencyUtil";
export default {
    emits: ['getData'],
    setup(props,context) {
        const addBorrow = () => {
            Toast.fail('借不了，有借无还呐。');
        }
        const loading = ref(false);
        const finished = ref(false);
        const refreshing = ref(false);

        const borrowData = ref({
            totalCollectAmount:0,
            totalPayAmount:0,
            summary:[]
        });

        const loadDate = async () => {
            request({
                method: 'post',
                url: "/api/pb/detail/borrow",
            }).then(function (response) {
                if (undefined == response.data || null == response.data || response.data.length == 0) {
                    finished.value = true;
                }
                borrowData.value = response.data;
                borrowData.value.summary.forEach(element => {
                    element.collectedAmount = toCurrency(element.collectedAmount);
                    element.receivablesAmount = toCurrency(element.receivablesAmount);
                    if(element.collectedAmount === element.receivablesAmount){
                        element.settle=true;
                    }else{
                        element.settle=false;
                    }
                });
                loading.value = false;
                if(refreshing.value){
                    refreshing.value=false;
                }
                context.emit('getData', {totalCollectAmount:response.data.totalCollectAmount,totalPayAmount:response.data.totalPayAmount});
            }).catch(function (error) {
                console.log('error');
                console.log(error);
            });
        }
        const onLoad=()=>{
            finished.value = true;
            loading.value = false;
        }

        const onRefresh = ()=>{
            loadDate();
        }
        loadDate();
        return {
            borrowData,
            addBorrow,
            loading,
            finished,
            refreshing,
            onLoad,
            onRefresh
        }
    }
}
</script>

<style scoped>
.br-panel {
    height: 14.5rem;
    overflow: scroll;
}

.borrow-repay-item {
    font-size: 0.4rem;
    color: grey;
}
.borrow-repay-item .unsettle{
    color: red;
}

.borrow-repay-item .row-date {
    background: #ebedf0;
}

.borrow-repay-item .row-desc {
    padding: 0.2rem 0rem 0.2rem 0rem;
}
.svg-icon {
    width: 2em;
    height: 2em;
    vertical-align: -2em;
    fill: currentColor;
    overflow: hidden;
    margin-left: 0.4rem;
    background-color: pink;
    color: white;
}

.borrow-repay-item .ml5 {
    margin-left: 5%;
}

.finish-btn {
    background: white;
    color: grey;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.15rem double grey;
}

.add-btn-par {
    border-top: 1px solid #f0f3f9;
    border-bottom: 1px solid #f0f3f9;
}

.add-btn {
    background: white;
    color: green;
    border: none;
}
</style>