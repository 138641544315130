<template>
  <van-nav-bar title="收支流水" class="page-nav-bar" />
  <monthly-cumulative />
  <div style="height: 80%;overflow: scroll;">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" offset="0">
      <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div v-for="(it, index) in recentConsumptionData" :key="index">
          <van-cell :title="it.date" title-class="title-left" :value="it.sum" class="summary" />
          <list-item :itemData="detail" v-for="(detail, index) in it.items" :key="index"
            @itemBillingClose="itemBillingClose"/>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import request from "../utils/request";
export default {
  components: {

  },
  setup() {
    const recentConsumptionData = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);

    const initRecentConsumptionData = (data) => {

      data.forEach((element) => {
        let crow = {
          date: '',
          sum: '',
          items: null,
        }
        let date = new Date(element.date);
        let today = new Date();
        var yestday = new Date(today.getTime() - 24 * 3600 * 1000);
        if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
          crow.date = '今天';
        } else if (yestday.getFullYear() === date.getFullYear() && yestday.getMonth() === date.getMonth() && yestday.getDate() === date.getDate()) {
          crow.date = '昨天';
        } else {
          crow.date = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        }

        let sumText = '';
        if (0 != element.iamountTotal) {
          sumText = '收入 ' + element.iamountTotal + ' ';
        }
        if (0 != element.eamountTotal) {
          sumText += '支出 ' + element.eamountTotal;
        }
        crow.sum = sumText;
        crow.items = element.items;
        recentConsumptionData.value.push(crow);
      });
      console.log(recentConsumptionData.value);
    };
    const page = {
      size: 10,
      current: 1
    };
    const loadDate = async () => {
      request({
        method: 'post',
        url: "/api/pb/detail/recentConsumptionPage",
        data: page
      }).then(function (response) {
        if (undefined == response.data || null == response.data || response.data.length == 0) {
          finished.value = true;
        }
        initRecentConsumptionData(response.data);
        loading.value = false;
      }).catch(function (error) {
        console.log('error');
        console.log(error);
      });
    }

    const onLoad = () => {
      if (refreshing.value) {
        recentConsumptionData.value = [];
        page.current = 1;
        refreshing.value = false;
      } else {
        if (!finished.value && recentConsumptionData.value.length > 0) {
          page.current += 1;
        }
      }
      loadDate();

    };

    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };

    const itemBillingClose = () => {
      onLoad();
    }

    return {
      recentConsumptionData,
      onLoad,
      loading,
      finished,
      onRefresh,
      refreshing,
      itemBillingClose,
    };
  },
};


</script>

<style scoped>
.van-hairline--bottom::after {
  border-bottom-width: 0rem;
}
</style>