<!--
* 逐月对比
-->
<template>
  <van-tabs v-model:active="activeName" swipeable>
    <van-tab title="逐月对比" name="mtmcTab">
      <div class="mtmcContainer">
        <van-dropdown-menu>
          <van-dropdown-item v-model="year" z-index="9999" :options="yearOption" @change="changeYear" />
        </van-dropdown-menu>
        <div class="mtmcBody">
          <ECharts id="mtmmcChart" cheight="200px" :option="option" />
          <div>
            <table class="mtmcTable">
              <tr>
                <th>月份</th>
                <th>收入</th>
                <th>支出</th>
                <th>结余</th>
              </tr>
              <tr v-for="(item, index) in mtmcDetails" :key="index">
                <td>{{ item.month }}</td>
                <td>{{ item.incomeMonth }}</td>
                <td>{{ item.expendMonth }}</td>
                <td>{{ item.balanceMonth }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </van-tab>
    <van-tab title="个人分析" name="analysisTab">
      <personalAnalysis/>
    </van-tab>
  </van-tabs>
</template>

<script>
import { ref, reactive } from 'vue';
import request from "../utils/request";
import { toCurrency } from "@/utils/currencyUtil";
import personalAnalysis from "@/components/PersonalAnalysis";
export default {
  components: {
    personalAnalysis
  },
  setup() {
    const activeName = ref('mtmcTab');

    const thisYear = new Date().getFullYear();
    const year = ref(thisYear);
    const yearOption = [];
    for (let i = 2018; i <= thisYear; i++) {
      yearOption.push({ text: '' + i, value: i });
    }
    const option = reactive({
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['收入', '支出', '结余']
      },
      grid: {
        top:'5%',
        left: '18%',
        right: '5%',
        bottom: '10%',
        //containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['1月', '', '3月', '', '5月', '', '7月', '', '9月', '', '11月', '']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '收入',
          type: 'line',
          //stack: 'Total',
          color: 'green',
          smooth: true,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: '支出',
          type: 'line',
          //stack: 'Total',
          color: 'red',
          smooth: true,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: '结余',
          type: 'line',
          //stack: 'Total',
          color: 'blue',
          smooth: true,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
      ]
    });

    const mtmcDetails = ref();
    const getMTMCData = async (y) => {
      request({
        method: 'post',
        url: "/api/pb/detail/mtmc",
        data: { 'year': y }
      }).then(function (response) {
        option.series[0].data = response.data.incomeChart;
        option.series[1].data = response.data.expendChart;
        option.series[2].data = response.data.balanceChart;
        //格式化
        response.data.detailList.forEach(element => {
          element.incomeMonth = toCurrency(element.incomeMonth);
          element.expendMonth = toCurrency(element.expendMonth);
          element.balanceMonth = toCurrency(element.balanceMonth);
        });
        mtmcDetails.value = response.data.detailList;
      }).catch(function (error) {
        console.log('error');
        console.log(error);
      });
    };
    getMTMCData(year.value);
    const changeYear = (value) => {
      //请求刷新数据
      getMTMCData(value);
    }
   
    return {
      activeName,
      year,
      yearOption,
      changeYear,
      option,
      mtmcDetails,
    };
  },
};
</script>


<style scoped>
.personal-van-tab {
  height: 19rem;
  overflow: hidden;
}

.mtmcContainer {
  height: 100%;
  overflow: hidden;
}
.van-dropdown-item {
  top: 6.5%!important;
}

.mtmcBody {
  overflow: scroll;
  height: 17.5rem;
}

.mtmcChart {
  padding-top: 1%;
}

.mtmcTable {
  width: 100%;
  font-size: 0.5rem;
  text-align: center;
  line-height: 1rem;
  padding: 2%;
}

.mtmcTable th {
  color: #8d8e96;
  font-size: smaller;
}

.mtmcTable td {
  font-size: smaller;
}

.mtmcTable td:first-child {
  color: #8d8e96;
}

.mtmcTable td:nth-child(2) {
  color: green;
}

.mtmcTable td:nth-child(3) {
  color: red;
}

.mtmcTable td:nth-child(4) {
  color: blue;
}
</style>